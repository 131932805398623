.route-map {
	width: 100%;

	&__pan {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, #FFFFFF 100%);
		}
	}

	&__svg {
		width: 100%;
		min-height: 240px;
		height: auto;

		.borders {
			fill: color('border', 'strong');
		}

		.route-path {
			stroke-width: 2px;
		}

		.ship {
			animation: ship 6s infinite linear;
			animation-delay: inherit;
			transform-box: fill-box;
			transform-origin: 50% 50%;
			animation-fill-mode: both;
			fill: #fff;
			
			@keyframes ship {
				0% {
					fill-opacity: 0;
					offset-distance: 0%;
				}
				40% {
					fill-opacity: 0;
					offset-distance: 0%;
				}
				50% {
					fill-opacity: 1;
				}
				90% {
					fill-opacity: 1;
				}
				100% {
					fill-opacity: 0;
					offset-distance: 100%;
				}
			}

			&-1 {
				offset-path: path('M694.387 298.852 204.975 220.77');
			}

			&-2 {
				offset-path: path('M699.433 299.139c-49.881-10.368-80.597-26.303-129.262-41.744-77.271-24.518-166.252-58.527-236.496-74.63-49.206-11.28-93.705-14.078-107.965-10.507');
			}

			&-3 {
				offset-path: path('M703.776 299.073c-78.67-25.718-218.653-82.55-313.78-126.175-56.548-28.292-76.423-86.772-86.84-120.457');
			}

			&-4 {
				offset-path: path('M694.387 298.852C363.94 253.492 259.5 253 240 330c-5.698 22.5-55 12.5-79.5 26.5-4.5 1.5-13.472 12.124-17.5 17-9.5 11.5-90 9.5-92.5 60.5');
			}

			&-5 {
				offset-path: path('M204.975,220.77 694.387,298.852');
			}

			&-6 {
				offset-path: path('M225.71000000000006,172.25799999999998C239.97000000000008,168.68699999999998 284.46900000000005,171.48499999999999 333.67500000000007,182.765C403.91900000000004,198.868 492.90000000000003,232.87699999999998 570.171,257.395C618.836,272.836 649.552,288.771 699.433,299.139');
			}

			&-7 {
				offset-path: path('M303.15599999999995,52.440999999999974C313.573,86.12599999999996 333.448,144.60599999999997 389.996,172.89799999999997C485.12299999999993,216.52299999999997 625.106,273.35499999999996 703.776,299.073');
			}

			&-8 {
				offset-path: path('M50.5,434C53,383 133.5,385 143,373.5C147.028,368.624 156,358 160.5,356.5C185,342.5 234.302,352.5 240,330C259.5,253 363.94,253.492 694.387,298.852');
			}
		}

		.train {
			offset-path: path('M992.916 362.253s-6.878 2.553-10.825 5.554c-3.946 3.002-2.918 15.726-20.502 7.121-17.583-8.606-24.684-12.675-41.671-20.999-3.224-1.58-5.628-.094-9.169-.695-6.614-1.124-9.649-4.252-16.188-5.756-6.25-1.438-10.132-.188-16.314-1.898-6.182-1.709-23.326-8.335-36.739-16.571-2.883-1.77-4.757-2.452-7.256-4.732-4.075-3.716-4.434-7.385-7.573-11.919 0 0-6.847-8.819-11.359-14.369-2.897-3.564-4.099-5.971-7.498-9.06-12.199-11.084-24.834-8.627-41.316-8.614-4.152.004-6.721 1.993-10.616.557-2.8-1.032-3.597-2.983-6.125-4.567-2.413-1.511-6.459-3.379-6.459-3.379l-6.311-1.856s-4.503-.438-7.387-.297c-2.732.133-6.942.928-6.942.928s-16.642 1.225-18.578 5.659c-1.935 4.433 0 5.078 0 8.651 0 5.859-9.378 11.711-9.378 11.711');
			animation: train 8s infinite linear;
			animation-direction: alternate;
			transform-box: fill-box;
			transform-origin: 50% 50%;
			fill: color('primary');
			stroke: color('background');
			stroke-width: 2px;

			@keyframes train {
				0% {
					fill-opacity: 0;
					stroke-opacity: 0;
					offset-distance: 0%;
				}
				10% {
					fill-opacity: 1;
					stroke-opacity: 1;
				}
				90% {
					fill-opacity: 1;
					stroke-opacity: 1;
				}
				100% {
					fill-opacity: 0;
					stroke-opacity: 0;
					offset-distance: 100%;
				}
			}

			path {
				fill-opacity: inherit;
			}
		}
		
		.sea {
			.area {
				fill: color('complementary');
			}

			.route-path {
				stroke: color('text', 'inverse');
				stroke-dasharray: 100% 50%;
			}

			text {
				fill: color('text', 'inverse');
			}

			&-black .route-path {
				animation: path-sea 6s infinite ease;
				animation-delay: inherit;
				transform-box: fill-box;
				transform-origin: 50% 50%;
				animation-fill-mode: both;
				
				@keyframes path-sea {
					0% {
						stroke-dashoffset: 100%;
						stroke-opacity: 0;
					}
					25% {
						stroke-opacity: .5;
					} 
					100% {
						stroke-dashoffset: 0%;
						stroke-opacity: 0;
					}
				}
			}

			&-caspian .route-path {
				stroke-dasharray: 8 4;
				animation: path-railway 10s infinite linear;
			}
		}

		.route {
			&-1 {
				animation-delay: 0s;
			}
			&-2 {
				animation-delay: 1s;
			}
			&-3 {
				animation-delay: 3.5s;
			}
			&-4 {
				animation-delay: 4.5s;
			}
			&-5 {
				animation-delay: 3s;
			}
			&-6 {
				animation-delay: 4s;
			}
			&-7 {
				animation-delay: 0.5s;
			}
			&-8 {
				animation-delay: 1.5s;
			}
		}

		.railway {
			.route-path {
				stroke: color('primary');
				stroke-width: 2px;
				stroke-dasharray: 8 4;
				animation: path-railway 10s infinite linear;
			}
		}

		.city {
			fill: color('icon');
			stroke: color('background');
			stroke-width: 2px;

			&-main {
				fill: color('accent');
				stroke-width: 4px;
				transform-box: fill-box;
				transform-origin: center;
				animation: city-main 1s infinite alternate ease;

				@-webkit-keyframes city-main {
					0% {
						transform: scale(1);
					}
					100% {
						transform: scale(.8);
					}
				}
				
				@keyframes city-main {
					0% {
						transform: scale(1);
					}
					100% {
						transform: scale(.8);
					}
				}
				
			}
		}

		.captions {
			text {
				fill: color('text', 'weak');
				font-size: .75rem;
			}
		}
	}
}

@-webkit-keyframes path-railway {
	from { stroke-dashoffset: 10%; }
	to { troke-dashoffset: -10%; }
}

@keyframes path-railway {
	from { stroke-dashoffset: -10%; }
	to { troke-dashoffset: 10%; }
}