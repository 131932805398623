@import "import/reset.min";
@import "node_modules/photoswipe/dist/photoswipe";
@import "node_modules/tiny-slider/dist/tiny-slider";
@import "node_modules/tippy.js/dist/tippy";
@import "node_modules/tippy.js/themes/light";
@import "node_modules/tippy.js/animations/scale";

.tippy {
    &-content {
        padding: 0.5rem 1rem;
    }
}

.pswp {
    &__bg {
        @include overlay;
    }

    &__button {
        svg {
            fill: rgba(255, 255, 255, .7);
        }
    }
}

.toast {
    display: grid;
    align-items: center;
    animation-duration: 150ms;
    margin: 0.5rem 0;
    width: 15rem;
    @include rounded;
    pointer-events: auto;
    color: color('text', 'inverse');
    background: color('primary');
    min-height: 3em;
    cursor: pointer;
    padding: 1rem;
    word-break: break-word;
    @include text-small;

    &-info {
        background: color('complementary');
    }

    &-success {
        background: color('positive');
    }

    &-error {
        background: color('negative');
    }

    &-warning {
        background: color('warning');
    }
}