.file {
    align-items: center;
    background: color('background');

    &:not(&--tiny) {
        display: flex;
        width: 100%;
        padding: 0.75rem 1rem;
        gap: 0.75rem;
        @include border;
        @include rounded;
        @include shadow-raised;

        .file__icon {
            @include square(2rem);
        }
    }

    &--tiny {
        display: inline-flex;
        gap: 0.25rem;
    }

    &__title {
        width: 100%;
        @include truncate-text;
        @include transition;
        font-weight: 500;
    }

    &__size {
        flex-shrink: 0;
        color: color('text', 'weak');
        text-transform: uppercase;
    }

    &:hover &__title {
        color: color('accent');
    }
}