.port {
	&-about {
		&__text {
			h1 {
				@include text-display-2;
			}

			p {
				margin-top: 1rem;
			}
		}
		
		&__image {
			width: 100%;
			height: auto;
		}
	}

	&-terminals {
		overflow: hidden;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2rem;

			@include mobile {
				margin-bottom: 1rem;
			}
		}

		&__title {
			@include truncate-text;
		}

		&__controls {
			display: flex;
			gap: 0.5rem;
			flex-shrink: 0;
		}

		&__slider {
			user-select: none;
			cursor: grab;
			white-space: nowrap;
			font-size: 0;

			&:not(.tns-slider) {
				overflow-x: auto;
				@include simple-scrollbar;
			}
		}

		.tns-inner {
			font-size: 0;
		}

		.tns-ovh {
			overflow: visible;
		}
	}

	&-terminal {
		width: 28.5rem;
		max-width: 100%;
		display: inline-block;
		vertical-align: top;
		white-space: normal;
		font-size: 1rem;

		@include mobile {
			width: 19rem;
		}

		& + & {
			width: 30rem;
			padding-left: 1.5rem;

			@include mobile {
				width: 20rem;
				padding-left: 1rem;
			}
		}

		&__cover {
			@include aspect-ratio(2, 1);
			pointer-events: none;
		}

		&__title {
			margin-top: 1rem;
			@include text-card-title;
			display: flex;
			align-items: center;
			gap: .5rem;
		}

		&__text {
			margin-top: 0.5rem;
			height: 7.75rem;
			overflow: hidden;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				height: 3rem;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
			}
		}

		&__button {
			margin-top: 0.75rem;
		}
	}

	&-indexes {
		&__title {
			margin-bottom: 2rem;

			@include mobile {
				margin-bottom: 1rem;
			}
		}

		&__charts {
			display: grid;
			grid-template-columns: repeat(2, 16.5rem);
			grid-gap: 1.5rem;

			@include mobile {
				grid-template-columns: 1fr;
				grid-gap: 1rem;
			}
		}

		&__chart {
			&-title {
				@include text-small;
			}
		}

		&__index {
			display: flex;
			align-items: center;
			gap: 1rem;

			&-value {
				font-size: 2rem;
				line-height: 3rem;
				font-weight: 600;
				color: color('primary');
				white-space: nowrap;
			}

			&-key {
				@include text-small;
				color: color('text', 'weak');
				width: 11rem;
			}
		}
	}

	&-history {
		$fixed-height: 23.5rem;
		$animation-duration: .7s;
		
		&__header {
			@extend .port-terminals__header;
		}

		&__title {
			@include truncate-text;
		}

		&__controls {
			display: flex;
			gap: 0.5rem;
			flex-shrink: 0;
		}

		&__timeline {
			display: flex;
			gap: .5rem;
			
			@include desktop {
				height: $fixed-height;
				align-items: start;
				flex-direction: column;
				justify-content: space-between;
			}

			@include mobile {
				overflow-x: scroll;
				overflow-y: hidden;
			}
		}

		&__year {
			color: color('text', 'weak');
			font-weight: 600;
			padding: .25rem .5rem;
			position: relative;
			@include rounded(2rem);
			overflow: hidden;
			flex-shrink: 0;
			width: 3.5rem;
			text-align: center;
			@include transition;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: url('../img/wave-substrate.svg') repeat-x 0 0 / 100%;
				background-position-x: 0;
				animation: wave 2s infinite linear;
				@include transition($animation-duration);
			}

			@keyframes wave {
				0% {
					background-position-x: 0;
				}
				100% {
					background-position-x: 3.5rem;
				}
			}

			span {
				position: relative;
			}
			
			&:not(&.tns-nav-active) {
				box-sizing: border-box;
				&:hover {
					cursor: pointer;
					color: color('primary');
					background: color('background', 'strong');
				}

				&::before {
					top: 100%;
				}
			}

			&.tns-nav-active {
				color: color('text', 'inverse');
			}
		}

		&__images {			
			@include desktop {
				height: $fixed-height;
			}

			@include mobile {
				display: flex !important;
				align-items: center;
			}

			img {
				width: 100%;
				@include transition($animation-duration);
			}

			&-item {
				display: flex;
				align-items: center;
				height: 100%;
				overflow: hidden;
				@include transition($animation-duration);

				&:not(.tns-slide-active) {
					filter: blur(4px) grayscale(100%);
					opacity: 0;
					visibility: hidden;

					img {
						transform: scale(.8);
					}
				}
			}
		}

		&__text {
			display: flex;
			background: color('background', 'strong');
			@include rounded;
			@include transition($animation-duration);

			@include desktop {
				height: $fixed-height;
				padding: 2.5rem 4.5rem 2.5rem 6rem;
				overflow-y: auto;
				@include simple-scrollbar;
			}

			@include mobile {
				padding: 1rem 1.5rem;
			}

			&:not(.tns-slide-active) &-inner {
				opacity: 0;
				transform: scale(0.9);
			}

			&-slider {
				@include mobile {
					display: flex;
				}
			}

			&-inner {
				@include transition($animation-duration);
				margin: auto;
			}
		}
	}

	&-equipment {
		overflow-x: hidden;

		&__divider {
			margin-top: 4rem;
			margin-bottom: 4rem;

			@include mobile {
				margin-top: 1.75rem;
				margin-bottom: 1.75rem;
			}
		}
		
		&__header {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
			margin-bottom: 1.5rem;
		}

		&__subtitle {
			@include text-label;
			color: color('text', 'weak');
		}

		&__slider {
			overflow: hidden;
			user-select: none;
			cursor: grab;
			white-space: nowrap;
			font-size: 0;

			&:not(.tns-slider) {
				overflow-x: auto;
				@include simple-scrollbar;
			}
		}

		&__slide {
			width: 22.5rem;
			max-width: 100%;
			display: inline-block;
			vertical-align: top;
			white-space: normal;
			font-size: 1rem;
	
			@include mobile {
				width: 19rem;
			}
	
			& + & {
				width: 24rem;
				padding-left: 1.5rem;
	
				@include mobile {
					width: 20rem;
					padding-left: 1rem;
				}
			}			
		}

		.tns-inner {
			font-size: 0;
		}

		.tns-ovh {
			overflow: visible;
		}
	}
}