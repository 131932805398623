.header {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: color('background', 'base');
    @include shadow-elevated;
    
    &__main {
        display: flex;
        align-items: center;
        height: 5rem;

        @include desktop {
            justify-content: space-between;
        }

        @include mobile {
            height: 4rem;
        }
    }

    &__logo {
        @include mobile {
            margin-right: auto;
        }
    }
}

.topbar {
    background: color('primary');
    color: color('text', 'inverse');
    height: 2.5rem;

    &__inner {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        @include transition;
    }

    &__group {
        display: flex;
    }

    &__item {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 .5rem;
        flex-shrink: 0;
        position: relative;

        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        @include link-hover;
        
        .icon:first-child {
            margin-right: 0.5rem;
        }
    }
}

.search {
    @extend .topbar;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    @include transition;

    &:not(&--shown) {
        opacity: 0;
        transform: translateX(10%) scale(.8);
        visibility: hidden;
    }

    &__inner {
        @extend .topbar__inner;
    }

    &__button {
        width: 2.5rem;
    }

    &__input {
        flex: 1 0 0;
        background: transparent;
        border: none;
        outline: none;
        font: inherit;
        color: inherit;
        @include text-small;

        &::placeholder {
            color: rgba(color('text', 'inverse'), 0.5);
        }
    }
}

.search--shown ~ .topbar .topbar__inner {
    opacity: 0;
    transform: translateX(-10%) scale(.8);
}

.logo {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &__mark {
        margin-right: 1rem;
        width: 2.25rem;
        height: 3rem;

        @include mobile {
            width: rem(30);
            height: rem(40);
        }
    }

    &__title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 800;

        @include mobile {
            font-size: 1rem;
            line-height: rem(20);
        }

        span {
            color: color('complementary');
        }
    }

    &__descriptor {
        font-size: rem(10);
        line-height: rem(14);
        font-weight: 600;
        color: color('text', 'weak');
        text-transform: uppercase;
    }
}

.mainmenu {
    &__expand {
        font-size: 0;
    }
    
    @include desktop {
        display: flex;
        height: 100%;

        &__item {
            position: relative;

            .icon--expand {
                margin-left: 0;
            }
        }
    
        &__link {
            display: flex;
            gap: .25rem;
            align-items: center;
            height: 100%;
            padding: 0 rem(12);
            border-bottom: 4px solid transparent;
            padding-top: 4px;
            @include text-link-small;
            @include transition;
    
            &--current {
                color: color('primary');
                border-color: color('primary');
            }
    
            &:not(&--current) {
                @include link-hover;
            }
        }

        &__toggle, &__overlay {
            display: none;
        }
    }

    @include mobile {
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        width: 80vw;
        max-width: 360px;
        height: 100%;
        overflow-y: scroll;
        background: color('background', 'base');

        @include transition;
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0;

        &__link {
            @include sidebar-menu-item;
            gap: .5rem;
            @include text-link-small;
            position: relative;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &__expand {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 1rem;
            margin-left: auto;
            border-left: $b-width solid color('border', 'base');
            
            .icon {
                @include transition;
            }

            &--turned {
                .icon {
                    transform: rotate(180deg);
                }
            }
        }

        &__submenu {
            @include transition(.5s);
            position: static;
            transform: none;
            max-height: 50vh;
            box-shadow: none;

            &:not(.context-menu--shown) {
                max-height: 0;
            }
        }

        &__toggle {
            display: block;
            padding: 0.5rem;
        }

        &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;

            @include overlay;
            @include transition;
            visibility: hidden;
            opacity: 0;
        }

        &--visible {
            transform: translateX(0);
            visibility: visible;
            opacity: 1;
        }

        &--visible + &__overlay {
            visibility: visible;
            opacity: 1;
        }
    }
}