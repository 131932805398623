// Components
@import "components/icon/icon";
@import "components/button/button";
@import "components/index/index";
@import "components/newscard/newscard";
@import "components/mediacard/mediacard";
@import "components/tender/tender";
@import "components/tag/tag";
@import "components/loader/loader";
@import "components/file/file";
@import "components/field/field";
@import "components/input/input";
@import "components/textarea/textarea";
@import "components/context-menu/context-menu";
@import "components/steps/steps";
@import "components/choice/choice";
@import "components/select/select";
@import "components/fileinput/fileinput";
@import "components/option/option";
@import "components/tabs/tabs";
@import "components/alert/alert";
@import "components/timeline/timeline";
@import "components/badge/badge";
@import "components/select-search/select-search";
@import "components/multiselect/multiselect";

// Modules
@import "modules/header/header";
@import "modules/footer/footer";
@import "modules/hero/hero";
@import "modules/modal/modal";
@import "modules/scheme/scheme";
@import "modules/route-map/route-map";

.page-header {
    display: flex;
    gap: 1.5rem;

    @include desktop {
        align-items: center;
        margin-bottom: 1.5rem;
    }

    @include mobile {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1.5rem;
    }

    &__title {
        display: flex;
        gap: .5rem;
        @include text-h2;
        overflow: hidden;
        width: 100%;

        span {
            @include truncate-text;
        }
    }

    &__controls {
        display: flex;
        gap: 1rem;
        justify-content: space-evenly;

        @include mobile {
            flex-wrap: wrap;

            & > * {
                flex: 1 0 auto;
            }
        }
    }

    & + .tabs {
        @include desktop {
            margin-top: -1rem;
        }

        @include mobile {
            margin-top: -0.5rem;
        }
    }
}

.sidebar {
    &__wrap {
        @include sticky;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 2rem;

            @include mobile {
                margin-bottom: 1rem;
            }
        }

        > * + * {
            margin-top: 1rem;

            @include mobile {
                margin-top: 0.5rem;
            }
        }
    }
}

.navlink {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    max-width: 100%;
    overflow: hidden;
    @include text-link-small;
    @include link-hover;
    color: color('text', 'weak');

    &--right {
        justify-content: end;
        margin-left: auto;
    }

    span {
        @include truncate-text;
    }

    .icon {
        flex-shrink: 0;
        @include square(1rem);
    }
}

.menu {
    @include border;
    border-top-width: 0;
    @include rounded;
    overflow: hidden;
    
    &__item {
        @include sidebar-menu-item;
    }
}

.subpage {
    display: flex;
    align-items: center;
    padding: 1.25rem 1.5rem 1.25rem 1rem;
    @include border;
    font-weight: 500;
    @include rounded;
    @include link-hover;

    &__icon {
        margin-right: 1rem;
    }
}

.userbar {
    @include desktop {
        display: inline-flex;
        align-items: center;
        padding: 0.5rem 0.75rem;
        @include shadow-raised;
        background: color('background', 'base');
        @include border;
        @include rounded;
        @include text-link-small;
        @include link-hover;

        &__icon {
            margin-right: 0.5rem;
        }
    }

    @include mobile {
        font-size: 0;
        padding: 0.5rem;

        .icon--expand {
            display: none;
        }
    }
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 22.5rem;
    max-width: 100%;
    margin: 0 auto;

    &__image {
        display: block;
        width: 15rem;
        height: 15rem;
        pointer-events: none;

        &--data {
            display: flex;
            padding: 1rem;
            align-items: end;
            justify-content: center;
            height: 10rem;
            gap: 6%;

            div {
                width: 20%;
                height: 100%;
                max-height: 20%;
                animation: data 1s infinite alternate ease;

                &:nth-child(1) {
                    animation-delay: 0;
                    background: #F5F9FC;
                }

                &:nth-child(2) {
                    animation-delay: .5s;
                    background: #DEE9F4;
                }

                &:nth-child(3) {
                    animation-delay: 1s;
                    background: #E9F0F8;
                }
            }

            @keyframes data {
                from {
                    max-height: 20%;
                }
                to {
                    max-height: 100%;
                }
            }
        }
    }

    &__text {
        color: color('text', 'weak');
        text-align: center;
    }
}

.property {
    display: flex;
    gap: 1rem;

    &--v {
        flex-direction: column;
        gap: 0.25rem;
    }

    &__key, &__value {
        flex: 1 0 0;
        overflow: hidden;
    }

    &--v &__key, &--v &__value {
        flex-basis: 100%;
    }

    &__key {
        color: color('text', 'weak');
    }
}

.draft {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1.5rem;
    background: color('background', 'strong');
    @include rounded;

    @include mobile {
        padding: 0.75rem 1rem;
    }

    &__main {
        flex-grow: 1;
        @include truncate-text;
    }

    &__title {
        @include text-label;
        @include link-hover;
    }

    &__subtitle {
        @include text-small;
        color: color('text', 'weak');
        margin-top: 0.25rem;
    }

    &__button {
        padding: 0.5rem;
        cursor: pointer;
    }
}

.box {
    $this: &;

    @include border;
    @include rounded;
    overflow: hidden;

    &__header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.5rem;
        background: color('background', 'strong');
        position: relative;

        @include mobile {
            padding: 0.75rem 1rem;
        }
    }

    &__title {
        @include text-label;
        @include truncate-text;
        flex: 1 0 0;
    }

    &__content {
        padding: 1rem 1.5rem;

        @include mobile {
            padding: 0.75rem 1rem;
        }

        & + & {
            border-top: $b-width solid color('border');
        }
    }

    &--expandable {
        #{$this}__header {
            cursor: pointer;

            &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjOTFCNURCIiBkPSJtNSA1LjMtMSAxIDQgNCA0LTQtMS0xLTMgMy0zLTNaIi8+PC9zdmc+');
                display: inline-block;
                @include square(1rem);
                @include transition;
            }
        }

        &#{#{$this}--expanded} #{$this}__header::after {
            transform: rotate(180deg);
        }

        #{$this}__content {
            @include transition;
            max-height: 100vh;
        }

        &:not(#{$this}--expanded) #{$this}__content {
            visibility: hidden;
            opacity: 0;
            padding-top: 0;
            padding-bottom: 0;
            max-height: 0;
        }
    }
}

.qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    position: relative;
    background: color('background', 'strong');
    @include rounded;

    @include mobile {
        padding: 1rem;
        gap: 1rem;
    }

    &--placeholder {
        flex-direction: row;
        gap: 1rem;
        padding: 1rem;

        .qr__text {
            text-align: left;
        }
    }

    &__icon {
        @include square(2.5rem);
    }

    &__image {
        width: 10rem;

        @include mobile {
            width: 7.5rem;
        }
    }

    &__text {
        @include text-small;
        color: color('text', 'weak');
    }

    &__controls {
        position: absolute;
        right: 1rem;
        top: 1rem;

        @include mobile {
            right: 0.5rem;
            top: 0.5rem;
        }
    }
}

.entity {
    &--center {
        text-align: center;
    }

    &__image {
        margin-bottom: 1rem;
    }
    
    &__title {
        @include text-card-title;
    }

    &__params {
        margin-top: 0.25rem;
        color: color('text', 'weak');
        font-size: 0;

        li {
            display: inline-block;
            font-size: 1rem;
        }

        li + li {
            margin-left: 0.5rem;

            &:before {
                content: '•';
                margin-right: 0.5rem;
            }
        }
    }
}

.feedback {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__icon {
        @include square(2rem);
        fill: color('positive');
    }

    &__message {
        @include text-card-title;
    }

    &__status {
        @include text-small;
        color: color('text', 'weak');
    }
}

.placeholder {
    display: block;
    width: 100%;
    height: 1.5em;
    background: color('background', 'strong');
    @include rounded;
    animation: placeholder 1s ease-in-out infinite alternate;

    @keyframes placeholder {
        0% {
            background: color('background', 'strong');
        }
    
        100% {
            background: color('border', 'base');
        }
    }    
}

.card {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    gap: .25rem;
    @include border;
    @include rounded;
    @include shadow-raised;

    @include mobile {
        padding: 0.75rem 1rem;
    }

    a#{&}:hover &__title {
        color: color('accent');
    }

    &__title {
        @include text-card-title;
        @include transition;
    }

    &__description {
        @include text-small;
        color: color('text', 'weak');
    }
}

.sticky-controls {
    @include sticky;
    padding: 0.75rem 1.5rem;
    z-index: 1;
    @include rounded;
    background: color('background');
    @include border;
    @include shadow-elevated;
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    
    @include mobile {
        flex-direction: column;
    }

    &__item {
        flex-shrink: 0;

        @include mobile {
            flex-grow: 1;
        }

        &--fillwidth {
            flex-grow: 1;
        }
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-evenly;

        @include mobile {
            & > * {
                flex-grow: 1;
            }
        }
    }
}

.daterangepicker {
    position: relative;

    &__window {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 100%;

        background: color('background');
        @include shadow-elevated;
        @include rounded;
        @include border;
        @include transition;
    }

    &:not(.shown) &__window {
        visibility: hidden;
        opacity: 0;
        transform: scale(.9) translateY(-5%);
    }

    &__group {
        position: relative;
        display: flex;
        align-items: center;

        & + & {
            border-top: $b-width solid color('border');
        }
    }

    &__label {
        width: 4rem;
        padding: 0 1rem;
        color: color('text', 'weak');
    }

    &__input {
        @extend %input;
        width: 100%;
        border-width: 0;
        box-shadow: none;
    }

    &__value {
        cursor: pointer;
    }
}

.widget {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include rounded;

    &__pictogram {
        @include square(3rem);
        flex-shrink: 0;
    }
}

.landing-block {
    & + & {
        margin-top: 8rem;

        @include mobile {
            margin-top: 3.5rem;
        }
    }

    &__title {
        @include text-h1;
    }
}