.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    @include text-link;
    @include rounded;
    @extend .text--nowrap;
    text-align: center;
    cursor: pointer;
    @include transition;

    &__icon {
        @include transition;
        fill: currentColor;
    }

    @include backgrounds;

    &--primary {
        &:hover, &:active, &:focus {
            background: color('accent');
            color: color('text', 'onBright');
        }
    }

    &--secondary {
        @include shadow-raised;
        @include link-hover;
        background: color('background');
        @include border;

        .button__icon {
            fill: color('icon');
        }
    }

    &--primary {
        .button__icon {
            fill: color('icon');
        }
        
        &:hover .button__icon,  &:focus .button__icon {
            fill: color('text', 'onBright');
        }
    }

    &--small {
        @include text-link-small;
        padding: 0.75rem 1rem;
    }

    &--xs {
        @include text-link-small;
        padding: 0.5rem 0.75rem;
        
        .button__icon {
            @include square(1.25rem);
        }
    }

    &--icon-only {
        padding: 1rem;
        font-size: 0;
    }

    &--small#{&}--icon-only {
        padding: 0.75rem;
    }

    &--xs#{&}--icon-only {
        padding: 0.5rem;
    }
    
    &--block {
        @include breakpoints {
            display: block;
            width: 100%;
        }
    }

    &:not(&--icon-only) &__icon {
        margin-left: -0.25rem;
        margin-right: 0.5rem;
    }

    &.is-loading {
        color: transparent;
    }

    &[disabled] {
        opacity: 0.8;
        cursor: not-allowed;
    }
}