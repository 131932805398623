.calc {
    &__result {
        height: 100%;
        justify-content: center;
    }

	&__value {
		background: color('primary');
		color: color('text', 'inverse');
		padding: 1rem;
		@include rounded;
		text-align: center;
	}

	&__sum {
		@extend .h2;
	}

	&__controls {
		display: flex;
		gap: 1rem;
		overflow-x: auto;

		@include desktop {
			justify-content: center;
		}

		& > * {
			flex-shrink: 0;
		}
	}
}