.alert {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: color('background', 'strong');
    padding: 1rem 1.5rem;
    @include rounded;

    @include mobile {
        padding: 0.75rem 1rem;
    }

    &--negative {
        background: color('negative', 'fade');
        color: color('negative');
    }

    &--positive {
        background: color('positive', 'fade');
        color: color('positive');
    }

    &--warning {
        background: color('warning', 'fade');
        color: color('warning');
    }
    
    b, strong {
        font-weight: bold;
    }

    i, em {
        font-style: italic;
    }

    &__icon {
        fill: currentColor;
    }
}