.loader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    &__icon {
        width: 2.5rem;
        height: 2.5rem;
        animation: loader 1.5s ease-in-out infinite;
    }
}

@keyframes loader {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}