.index {
    display: flex;
    
    &__icon {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 1.5rem;
    }

    &__value {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 700;
        color: color('primary');

        @include mobile {
            font-size: 1.5rem;
            line-height: 2.25rem;
        }
    }

    &__measure {
        color: color('text', 'weak');
    }

    &__description {
        @include text-default;
    }
}