.multiselect {
	position: relative;
	
	&__options {
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
		right: 0;
		max-height: 20rem;
		overflow-y: auto;
		@include simple-scrollbar;
		background: color('background');
		@include border;
		@include rounded;
		@include shadow-elevated;
		@include transition;

		&:not(&--visible) {
			opacity: 0;
			visibility: hidden;
			transform: translateY(-1rem);
		}
	}

	&__option {
        @include text-small;
        @include transition;
        @include link-hover;
    
        display: flex;
		align-items: center;
		gap: 0.5rem;
        padding: 0.75rem 1rem;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:not(:first-child) {
            border-top: $b-width solid;
            border-color: inherit;
        }

		&--suitable {
			color: color('text', 'weak');
			
			b {
				color: color('text');
				font-weight: 500;
			}
		}
	}

	&__expand {
		position: absolute;
		@include square(1rem);
		right: 1rem;
		top: calc(50% - 0.5rem);
	}

	&__input {
		padding-right: 3rem;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}