.option {
    display: flex;
    align-items: center;
    
    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    &__label {
        margin-left: 0.75rem;
        font-weight: 500;
    }

    &__mark {
        display: flex;
        align-items: center;
        justify-content: center;
        @include square(1.5rem);
        @include rounded;
        border: $b-width solid color('border', 'strong');
        background: color('background');
        @include shadow-raised;
        @include transition;

        &::before {
            content: '';
            @include transition(.4s);
        }

        &:not(&--switch) {
            input[type=radio] + & {
                @include rounded(50%);
        
                &::before {
                    background: color('text', 'inverse');
                    @include square(.75rem);
                    @include rounded(50%);
                }
            }
            
            &::before {
                @include square(1.25rem);
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTcgNCA4IDE0bC00LTQtMSAxIDUgNUwxOCA2bC0xLTJaIi8+PC9zdmc+") no-repeat center / 100%;
                transform: scale(0);
            }

            input:checked + & {
                background: color('primary');
                border-color: color('primary');

                &::before {
                    transform: scale(1);
                }
            }

            input:indeterminate + & {
                &::before {
                    transform: scale(1);
                    background: color('primary');
                    @include square(.75rem);
                    @include rounded;
                }
            }

            input[type=radio]:indeterminate + & {
                &::before {
                    @include rounded(100%);
                }
            }

            input:disabled + & {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        &--switch {
            width: 2.5rem;
            padding: 0.25rem;
            @include rounded(2.5rem);
            position: relative;
    
            &::before {
                content: '';
                @include square(1rem);
                @include rounded(100%);
                background: color('border', 'strong');
                position: absolute;
                top: calc(50% - 0.5rem);
                left: 0.25rem;
            }

            input:checked + & {
                background: color('primary');

                &::before {
                    background: color('background');
                    left: calc(100% - 1rem - 0.25rem);
                }
            }
        }
    }
}