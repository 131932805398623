.field {
    display: block;
    width: 100%;

    > * + * {
        margin-top: 0.5rem;
    }

    &--error {
        .field__label {
            color: color('negative');
        }

        .input, .textarea {
            border-color: color('negative', 'fade');
            background: color('negative', 'fade');
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__label {
        @include text-label;
    }

    &__link {
        @include text-label;
        @include link-hover;
        color: color('text', 'weak');
    }

    &__error {
        @include text-small;
        font-weight: 500;
        color: color('negative');
    }

    &__description {
        @include text-small;
        color: color('text', 'weak');
        
        a {
            color: color('primary');
            @include link-hover;
        }
    }
}