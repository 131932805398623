@media print {
	@page {
		size: A4 portrait;
	}
}

.print {
	background: color('background', 'strong');

	&, body {
		font-size: 18px;
	}

	body {
		width: 210mm;
		min-height: 297mm;
		position: relative;

		@media screen {
			margin: 2rem auto;
			padding: 2rem;
			@include rounded;
			@include shadow-elevated;
		}
	}

	b {
		font-weight: 600;
	}

	&__running {
		display: flex;
		align-items: center;
		gap: 1rem;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid color('border');
		// position: fixed;
		// top: 0;
		// left: 0;
		// z-index: 9;
		// background: color('background');
	}

	&__logo {
		height: 3rem;
		width: auto;
	}

	&__company {
		font-size: 0.75rem;
		line-height: 1rem;
		color: color('text', 'weak');
	}

	&__header {
		margin: 2rem 0;
	}

	&__title {
		@extend .h1;
	}

	&__subtitle {
		font-weight: 500;
		color: color('text', 'weak');
	}

	&__block {
		@include rounded;
		page-break-inside: avoid;
		position: relative;

		&--bordered {
			border-top: 1px solid color('border');
			padding: 1rem 0;
		}

		& + & {
			margin-top: 1rem;
		}
	}

	&__stamp {
		position: absolute;
		pointer-events: none;
		width: 40mm;
		height: 40mm;
		mix-blend-mode: darken;
		z-index: 0;
		right: 0;
		bottom: 0;
	}

	&__destination {
		margin-bottom: 4rem;

		&-row {
			display: flex;
			align-items: center;

			& + & {
				margin-top: 1rem;
			}
		}

		&-text {
			width: 50%;
			margin-left: auto;
			text-align: right;
		}

		&-logo {
			height: 3rem;
			margin-right: auto;
		}
	}

	&__table {
		word-break: break-word;
		hyphens: auto;
		max-width: 100%;
	}

	&-passes {
		display: grid;
		grid-template-columns: 45%;
		grid-template-rows: repeat(5, 1fr);
		align-items: center;
		justify-content: center;
		padding: 0 !important;
	}

	&-pass {
		font-size: 11pt;
		line-height: 1.25;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			@include square(120px);
		}

		&__info {
			margin-left: 1rem;
			display: flex;
			flex-direction: column;
			gap: .25em;
		}
	}
}