.badge {
	display: inline-flex;
	align-items: center;
	align-self: center;
	flex: 0 0 1.5rem;
	justify-content: center;
	@include square(1.5rem);
	@include rounded(100%);
	@include backgrounds();
	background: color('primary');

	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	color: color('text', 'inverse');

	&--xs {
		font-size: 0;
		color: transparent;
		@include square(.5rem);
		flex-basis: .5rem;
	}
}