.tender {
    background: color('background', 'base');
    padding: 1rem 1.5rem;
    @include border;
    @include rounded;
    @include shadow-raised;
    cursor: pointer;

    @include mobile {
        padding: 1rem;
    }

    &__title {
        @include transition;
    }

    &:hover &__title {
        color: color('accent');
    }

    &__details {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-top: 1rem;

        @include mobile {
            margin-top: 0.75rem;
        }
    }
}