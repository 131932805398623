// Media queries
@mixin mobile {
    @media (max-width: $container-width) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $container-width) {
        @content;
    }
}

@mixin breakpoints {
    @content;

    @include desktop {
        &-d {
            @content;
        }
    }

    @include mobile {
        &-m {
            @content;
        }
    }
}

// Styles
@mixin backgrounds($colorNames...) {
    $styles: (
        'primary': (
            'background': color('primary'),
            'text': color('text', 'inverse'),
        ),
        'secondary': (
            'background': color('background'),
            'text': color('text'),
        ),
        'negative': (
            'background': color('negative'),
            'text': color('text', 'inverse'),
        ),
        'negative-fade': (
            'background': color('negative', 'fade'),
            'text': color('negative'),
        ),
        'positive': (
            'background': color('positive'),
            'text': color('text', 'inverse'),
        ),
        'positive-fade': (
            'background': color('positive', 'fade'),
            'text': color('positive'),
        ),
        'warning': (
            'background': color('warning'),
            'text': color('text', 'inverse'),
        ),
        'warning-fade': (
            'background': color('warning', 'fade'),
            'text': color('warning'),
        ),
        'extra-1': (
            'background': color('extra', '1'),
            'text': color('text', 'inverse'),
        ),
        'extra-2': (
            'background': color('extra', '2'),
            'text': color('text', 'inverse'),
        ),
        'extra-3': (
            'background': color('extra', '3'),
            'text': color('text', 'inverse'),
        ),
        'extra-4': (
            'background': color('extra', '4'),
            'text': color('text', 'inverse'),
        ),
        'extra-5': (
            'background': color('extra', '5'),
            'text': color('text', 'inverse'),
        ),
        'extra-6': (
            'background': color('extra', '6'),
            'text': color('text', 'inverse'),
        ),
        'extra-7': (
            'background': color('extra', '7'),
            'text': color('text', 'inverse'),
        ),
    );

    @if(list.length($colorNames) == 0) {
        $colorNames: map.keys($styles);
    }

    @each $color in $colorNames {
        $current: map-get($styles, $color);

        &--#{$color} {
            @include add-background(map-get($current, 'background'), map-get($current, 'text')) {
                @content;
            };
        }
    }
}

@mixin add-background($bg, $color) {
    background: $bg;
    color: $color;
    @content;
}

@mixin colors($colorNames...) {
    $styles: (
        'weak': color('text', 'weak'),
        'accent': color('accent'),
        'primary': color('primary'),
        'negative': color('negative'),
        'positive': color('positive'),
        'warning': color('warning'),
        'extra-1': color('extra', '1'),
        'extra-2': color('extra', '2'),
        'extra-3': color('extra', '3'),
        'extra-4': color('extra', '4'),
        'extra-5': color('extra', '5'),
        'extra-6': color('extra', '6'),
        'extra-7': color('extra', '7'),
    );

    @if(list.length($colorNames) == 0) {
        $colorNames: map.keys($styles);
    }

    @each $color in $colorNames {
        &--#{$color} {
            color: map-get($styles, $color);            
            @content;
        }
    }
}

@mixin border($color: 'border') {
    border-width: $b-width;
    border-style: solid;
    border-color: color($color);
}

@mixin shadow-elevated {
    box-shadow: 0px 16px 24px rgba(5, 38, 74, 0.03);
}

@mixin shadow-raised {
    box-shadow: 0px 2px 4px rgba(73, 97, 120, 0.05);
}

@mixin rounded($radius: $b-radius) {
    border-radius: $radius;
}

@mixin square($size: 1rem) {
    width: $size;
    height: $size;
}

@mixin aspect-ratio($horizontal: 1, $vertical: 1) {
    overflow: hidden;
    position: relative;
    padding-bottom: calc($vertical / $horizontal * 100%);

    img, video, iframe {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include rounded;
    }
}

@mixin overlay {
    background: rgba(0, 0, 0, .5);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

@mixin sticky {
    position: sticky;
    top: calc($h-header-d + 1rem);

    @include mobile {
        top: calc($h-header-m + 1rem);
    }
}

@mixin simple-scrollbar {
    $size: 8px;

    &::-webkit-scrollbar {
        @include desktop {
            @include square(.5rem);
        }

        @include mobile {
            @include square(.25rem);
        }
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: color('border', 'strong');
        @include rounded;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: color('border', 'strong');
    }
}

// Transitions
@mixin transition($duration: .2s) {
    transition: all $duration ease-in-out;
}

@mixin link-hover {
    @include transition;
    cursor: pointer;
    
    &:hover {
        color: color('accent');
    }
}

// Elements
@mixin sidebar-menu-item {
    display: flex;
    padding: 1rem 1rem;
    border-top: $b-width solid color('border', 'base');
    border-left: 4px solid transparent;
    @include text-small;
    @include transition;

    &:not(&--current) {
        @include link-hover;
    }

    &--current {
        color: color('primary');
        border-left-color: color('primary');
        cursor: default;
    }
}

%input {
    display: block;
    width: 100%;
    flex-grow: 1;
    // -webkit-min-logical-width: calc(100% - 16px);
    // min-width: 15rem;
    min-height: 3.5rem;
    @include border;
    padding: 1rem;
    @include rounded;
    @include shadow-raised;
    font: unset;
    @include text-default;
    color: color('text');
    background: color('background');
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-webkit-date-and-time-value {
        text-align-last: left;
    }

    &::placeholder {
        color: color('text', 'placeholder');
    }

    &:disabled {
        background: color('background', 'strong');
        cursor: not-allowed;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    &[type='number'] {
        -moz-appearance:textfield;
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
        margin: 0;
    }
}

@mixin input() {
    @extend %input;

    &__wrap {
        position: relative;
        display: flex;
        align-items: stretch;
    }

    &__icon {
        position: absolute;
        pointer-events: none;
        left: 1rem;
        top: calc(50% - 0.75rem);
    }

    &__icon ~ & {
        padding-left: 3.25rem;
    }
}