.footer {
    background: color('primary');
    color: color('text', 'inverse');
    padding: 1.5rem 0;

    &__inner {
        @include desktop {
            display: flex;
            align-items: center;
        }
    }

    &__copyright {
        @include link-hover;
    }

    &__menu {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            margin-top: .5rem;
        }
    }

    &__link {
        @include text-link-small;
        @include link-hover;

        @include desktop {
            margin-left: 1rem;
        }

        @include mobile {
            margin-top: 0.5rem;
            width: 50%;
        }
    }
}