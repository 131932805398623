.about {
    overflow-x: hidden;

    & > * + * {
        margin-top: 4rem;
    }
    
    &__scheme {
        svg {
            width: 100%;
            height: auto;
            min-width: 0;
        }
    }

    &__text {
        @include text-default;

        * + * {
            margin-top: 1.5rem;
        }
    }
    
    &__indexes {        
        @include mobile {
            margin-top: 2.5rem;
        }
    }

    &__period {
        @include text-default;
        color: color('text', 'weak');
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 3.5rem;
            height: $b-width;
            background: color('border', 'base');
            margin-right: 1.5rem;
        }
    }
}

.services {
    &__inner {
        align-items: center;
    }
    
    &__title {
        @include text-h1;
    }

    &__description {
        @include text-default;
        margin-top: 1rem;
    }

    &__list {
        margin-top: 1rem;
    }

    &__item {
        display: flex;
        align-items: center;
        @include text-default;
        font-weight: 500;

        & + & {
            margin-top: 0.5rem;
        }
    }

    &__icon {
        margin-right: 0.75rem;
    }

    &__button {
        margin-top: 1.5rem;
    }

    &__image {
        @include mobile {
            margin-bottom: .5rem;
        }

        img {
            @include rounded;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.news {
    &__title {
        @include text-h1;
        margin-bottom: 2rem;

        @include mobile {
            margin-bottom: 1.5rem;
        }
    }

    &__button {
        margin-top: 2rem;

        @include mobile {
            margin-top: 1.5rem;
        }
    }
}

.partners {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    padding: 1.5rem;
    background: color('background', 'strong');
    overflow-x: auto;
    @include simple-scrollbar;

    &__item {
        margin: 0 auto;

        img {
            display: block;
            height: 6rem;
            width: auto;
            mix-blend-mode: multiply;
        }
    }
}