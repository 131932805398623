.timeline {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 11px;
        top: 0;
        height: 100%;
        width: 2px;
        background: color('icon');
        background: linear-gradient(to bottom, transparent 50%, color('icon') 50%);
        background-size: 100% 8px;
    }
}

.timeline-item {
    $this: &;  

    display: flex;
    position: relative;

    & + & {
        margin-top: 0.75rem;
    }

    &--weak {
        color: color('text', 'weak');
    }

    &--success {
        #{$this}__icon {
            fill: color('positive');
        }
    }

    &--warning {
        #{$this}__icon {
            fill: color('warning');
        }
    }

    &--danger {
        #{$this}__icon {
            fill: color('negative');
        }
    }

    &__icon {
        background: color('background', 'base');
        margin-right: 0.75rem;
    }

    &__title {
        font-weight: 500;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    &__text {
        @include text-small;
    }

    &__params {
        @include text-small;
        color: color('text', 'weak');
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        .icon {
            @include square(1rem);
        }
    }
}