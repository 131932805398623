// Colors
$c-primary: #074181;
$c-complementary: #00B7CB;
$c-accent: #FEC50C;

$colors: (
	'background': (
		'base': #FFFFFF,
		'strong': #F5F8FC,
		'focus': rgba($c-accent, 0.05),
	),
	'text': (
		'base': #010D1B,
		'weak': #8495A8,
		'placeholder': #CCD5DE,
		'inverse': #FFFFFF,
		'onBright': rgba(0, 0, 0, 0.65),
	),
	'border': (
		'base': #EAF2FA,
		'strong': #B8C8D9,
		'focus': rgba($c-accent, 0.2),
	),
	'icon': (
		'base': #91B5DB,
		'complementary': $c-complementary,
	),
	'primary': (
		'base': $c-primary
	),
	'complementary': (
		'base': $c-complementary
	),
	'accent': (
		'base': $c-accent
	),
	'negative': (
		'base': #DF1F1F,
		'fade': rgba(#DF1F1F, 0.05),
	),
	'positive': (
		'base': #07810C,
		'fade': rgba(#07810C, 0.05),
	),
	'warning': (
		'base': #F28300,
		'fade': rgba(#F28300, 0.05),
	),
	'extra': (
		'1': #F44336,
		'2': #8D6E63,
		'3': #9C27B0,
		'4': #FF9800,
		'5': #5C6BC0,
		'6': #2196F3,
		'7': #009688
	)
);

// Sizes
$container-width: 1128px;

$h-header-d: 7.5rem;
$h-header-m: 6.5rem;
$h-footer-d: 4.5rem;
$h-footer-m: 10.5rem;

// Border
$b-radius: 2px;
$b-width: 1px;