.typeset {
    h1 { @extend .h1; }
    h2 { @extend .h2; }
    h3 { @extend .h3; }
    ul { @extend .ul; }
    
    time {
        @include text-small;
        color: color('text', 'weak');
    }

    &__heading {
        margin-bottom: 1.5em;

        @include mobile {
            margin-bottom: 1em;
        }
    }

    > * + * {
        margin-top: 1em;

        @include mobile {
            margin-top: 0.75em;
        }
    }

    img {
        max-width: 100%;
    }

    a:not(.file):not(.button) {
        @include text-link-inline;
    }

    b, strong {
        font-weight: bold;
    }

    small {
        @include text-small;
        color: color('text', 'weak');
    }

    i, em {
        font-style: italic;
    }

    table {
        @extend .table;
    }
}

hr, .hr {
    display: block;
    border: none;
    position: relative;
    height: 4em;

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
    }

    &::before {
        left: 0;
        width: 100%;
        height: $b-width;
        background: color('border', 'base');
    }

    &::after {
        left: 50%;
        width: 3.5em;
        height: 2em;
        margin-top: -1em;
        margin-left: -1.75em;
        background: url(../img/anchor.svg) color('background', 'base') no-repeat center / 2em;
    }
}

.h1 { @include text-h1; @include heading-spacing; }
.h2 { @include text-h2; @include heading-spacing; }
.h3 { @include text-h3; @include heading-spacing; }

.ul {
    @include ul;
}

.text {
    @include colors('weak');

    &--label {
        font-size: 1rem;
        line-height: 1.5rem;
        color: color('text');
        font-weight: 500;
    }

    &--heavy {
        font-weight: 600;
    }

    &--nowrap {
        @include breakpoints {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &--small {
        @include breakpoints {
            @include text-small;
        }
    }

    &--hidden {
        @include breakpoints {
            font-size: 0;
        }
    }

    &--center {
        @include breakpoints {
            text-align: center;
        }        
    }

    &--right {
        @include breakpoints {
            text-align: right;
        }
    }
}

.link {
    @include text-link-inline;
}