.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include mobile {
        gap: 1rem;
    }

    &__row {
        display: flex;
        width: 100%;
        gap: inherit;

        @include mobile {
            flex-direction: column;
        }
    }

    &__navlinks {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        padding: 1rem;
        border-top: $b-width solid color('border', 'base');
    }

    &__footer {
        display: flex;
        gap: inherit;
        padding: 1rem;
        background: color('background', 'strong');
        @include rounded;

        @include desktop {
            > *:last-child {
                margin-left: auto;
            }
        }

        @include mobile {
            flex-direction: column-reverse;
        }
    }

    &__navlinks + &__footer {
        margin-top: -1.5rem;

        @include mobile {
            margin-top: -1rem;
        }
    }
}

.form-subheader {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__title {
        font-weight: 600;
        @include truncate-text;
    }

    &__line {
        height: $b-width;
        background: color('border', 'base');
        flex-grow: 1;
    }

    &__button {
        padding: 0.5rem;
        cursor: pointer;
        @include border;
        @include rounded;
        @include shadow-raised;
    }
}