.choice {
    display: flex;
    @include shadow-raised;

    @include mobile {
        flex-direction: column;
    }

    &__item {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        overflow: hidden;

        @include mobile {
            flex-basis: auto;
        }

        & + & {
            .choice__button {
                @include desktop {
                    border-left-width: 0;
                }

                @include mobile {
                    border-top-width: 0;
                }
            }
        }
    }

    &__button {
        flex: 1 1 auto;
        @include border;
        padding: 1rem 1.5rem;
        text-align: center;
        font-weight: 500;
        
        @include rounded;
        @include truncate-text;

        input:checked + & {
            background: color('primary');
            border-color: color('primary');
            color: color('text', 'inverse');
        }

        &--negative {
            background: color('negative', 'fade');
            border-color: color('negative', 'fade');
            color: color('negative');
        }

        input:checked + &--negative {
            background: color('negative');
            border-color: color('negative');
        }

        &--positive {
            background: color('positive', 'fade');
            border-color: color('positive', 'fade');
            color: color('positive');
        }

        input:checked + &--positive {
            background: color('positive');
            border-color: color('positive');
        }
    
        input:not(:checked) + & {
            @include link-hover;
        }
    }

    input {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
}