.newscard {
    display: block;
    $this: &;

    &__image {
        @include aspect-ratio(4, 3);
        flex-shrink: 0;

        @include mobile {
            margin-bottom: 0.75rem;
        }
    }

    &__content {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            display: block;
            height: 3rem;
            background: linear-gradient(180deg, rgba(color('background', 'base'), 0) 0%, color('background', 'base') 100%);
        }
    }

    &__date {
        @include text-small;
        color: color('text', 'weak');
    }

    &__title {
        @include transition;
    }

    &:hover &__title {
        color: color('accent');
    }

    &:not(&--tiny) {
        #{$this}__image {
            margin-bottom: 1rem;
        }

        #{$this}__content {
            height: 9rem;
        }

        #{$this}__date {
            margin-bottom: 0.5rem;

            @include mobile {
                margin-bottom: 0.25rem;
            }
        }

        #{$this}__title {
            @include text-card-title;
        }

        #{$this}__text {
            margin-top: 0.75rem;

            @include mobile {
                margin-top: 0.5rem;
            }
        }
    }
    
    &--tiny {
        display: flex;
        height: 5.625rem;

        #{$this}__image {
            width: 7.5rem;
            height: 100%;
            padding-bottom: 0;
            margin-right: 1rem;
        }

        #{$this}__date {
            margin-bottom: 2px;
        }

        #{$this}__title {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
        }

        #{$this}__text {
            @include text-small;
            margin-top: 0.25rem;
        }
    }
}