.context-menu {
    position: absolute;
    left: 0;
    top: calc(100% + .25rem);
    z-index: 100;

    display: block;
    width: 17rem;
    min-width: 100%;
    background: color('background', 'base');
    @include rounded;
    overflow: hidden;
    border: 1px solid color('border', 'base');

    visibility: hidden;
    transform: translateY(-1rem);
    opacity: 0;

    color: color('text');
    @include shadow-elevated;
    @include transition;
    
    &--hug {
        width: auto;
    }

    &--right {
        left: auto;
        right: 0;
    }

    &--bottom {
        top: auto;
        bottom: calc(100% + .25rem);
    }

    &--hug {
        width: auto;
    }

    &--attached {
        top: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &--primary {
        background: color('primary');
        color: color('text', 'inverse');
        border-color: rgba(color('text', 'inverse'), 0.1);
    }

    &__wrap {
        position: relative;
    }

    &__wrap:hover &--hoverable, &__wrap:focus &--hoverable, &__wrap > *:focus ~ &--hoverable, &--shown, &:has(:focus) {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
    }

    &__item {
        @include text-small;
        @include transition;
        @include link-hover;
    
        display: block;
        padding: 0.75rem 1rem;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include backgrounds('positive-fade', 'negative-fade', 'positive', 'negative');

        &:not(:first-child) {
            border-top: $b-width solid;
            border-color: inherit;
        }
    }
}