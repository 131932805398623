$inactive: color('icon');
$bg: color('background', 'strong');

.scheme {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	position: relative;
	width: 100%;
	user-select: none;
	background: color('background', 'strong');
	@include rounded;

	&--instant {
		background: none;
	}

	@include mobile {
		gap: .75rem;
	}

	%interactive {
		cursor: pointer;
		@include transition;
		transition-delay: .1s;
		stroke: transparent;
		stroke-width: 1rem;
		stroke-opacity: 0;
		stroke-linejoin: bevel;
	}

	&__controls {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1.5rem;
		width: 100%;

		@include mobile {
			gap: 0.75rem;
		}
	}

	&__zoom {
		display: flex;
		align-items: center;
		gap: 1rem;

		&-button {
			@include square(2rem);
			
			.icon {
				@include square(1rem);
			}
		}
	}
	
	&__scale {
		flex-grow: 1;
		min-width: 10rem;
		-webkit-appearance: none;
		appearance: none;
		height: 2px;
		background: color('border', 'base');
		outline: none;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			cursor: pointer;
			width: .75rem;
			height: 1.25rem;
			background: color('icon');
			border: .25rem solid $bg;
			box-shadow: none;
		}

		&::-moz-range-thumb {
			cursor: pointer;
			width: .75rem;
			height: 1.25rem;
			background: color('icon');
			border: .25rem solid $bg;
			box-shadow: none;
		}
	}

	&__details {
		display: flex;
		gap: 1.5rem;
		justify-content: space-between;

		@include mobile {
			gap: .75rem;
		}
	}

	&__detail  {
		display: flex;
		flex-shrink: 0;
		font-size: .75rem;
		line-height: 1rem;
		font-weight: 500;
		color: color('text', 'weak');

		.icon {
			@include square(2rem);
			margin-right: 0.5rem;
			float: left;
		}
	}

	&__pan {
		display: flex;
		justify-content: center;
		@include rounded;

		@include mobile {
			margin: 0 (-1 * $grid-gap-h-m);
		}
	}

	&__svg {
		width: 100%;
		min-height: 240px;
		height: auto;
	
		.port {
			&-area {
				fill: color('primary')
			}
	
			&-building {
				fill: rgba(255, 255, 255, .6);
	
				@keyframes fill {
					0% {
						fill-opacity: 0.5;
					}

					100% {
						fill-opacity: 0.75;
					}
				}

				&:hover {
					fill: rgba(255, 255, 255, .5);
				}

				.port-building-rest, .port-building-item {
					@include transition;
				}

				.port-building-rest {
					fill-opacity: 0.5;
				}

				.port-building-item {
					@extend %interactive;
					animation: fill 1s infinite ease alternate;
					animation-play-state: inherit;

					&:nth-child(even) {
						animation-delay: .3s;
					}

					&:hover {
						fill: rgba(255, 255, 255, 1);
						animation-play-state: paused;
					}
				}
			}
	
			&-road, &-rail {
				fill: rgba(255, 255, 255, .1);
			}
	
			&-park {
				fill: rgba(255, 255, 255, .05);
			}
		}
	
		.terminals {	
			.terminals-item {
				&:hover {
					.terminals-berth:not(:hover):not(.active) {
						fill: $inactive;
					}
				}
			}

			.terminals-area, .terminals-berth {
				@extend %interactive;
			}

			&:hover > .terminals-item:not(.active):not(:hover) {
				fill: $inactive;
			}
	
			&-item {
				&[data-name="dry-cargo"] {
					fill: color('extra', '7');
				}
	
				&[data-name="oil"] {
					fill: color('extra', '4');
				}
	
				&[data-name="passenger"] {
					fill: color('extra', '6');
				}
	
				&[data-name="railway"] {
					fill: color('extra', '5');
				}
	
				&[data-name="container"] {
					fill: color('extra', '2');
				}
			}
	
			&-berth-num {
				fill: #fff;
			}
		}

		.raid {
			fill: color('complementary');
		}

		.arrival {
			.ship {
				fill: color('complementary');
			}

			&-path {
				fill: transparent;
				stroke: color('border', 'strong');
				stroke-dasharray: 0.5rem 0.5rem;
			}
		}
	}
}