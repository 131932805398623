.contacts {
    &__section {
        background: color('background', 'strong');
        @include rounded;
        padding: 1rem 1.5rem;
        
        & + & {
            margin-top: 1rem;
        }
    }

    &__subtitle {
        @include text-label;
        margin-bottom: 0.75rem;
    }

    &__button {
        margin-top: 1rem;
    }

    &__map {
        height: 100%;
        background: color('background', 'strong');
        position: relative;

        @include mobile {
            height: 20.5rem;
        }

        #map {
            @include rounded;
            height: 100%;
        }
    }

    &__compass {
        display: flex;
        align-items: center;
        justify-content: center;
        @include square(5.5rem);
        background: url('../img/compass.svg') no-repeat center / contain,
                    linear-gradient(180deg, #F5F7FA 0%, #FFFFFF 50.0%);
        border-radius: 50%;
        @include shadow-elevated;
        position: absolute;
        top: -1rem;
        right: -1rem;
        z-index: 1;

        .icon {
            @include square(2.5rem);
            animation: compass .3s ease-in-out infinite;
        }
    }
}

@keyframes compass {
    0% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }
    
    100% {
        transform: rotate(-5deg);
    }
}