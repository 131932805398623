.hero {
    text-align: center;
    background: color('primary');
    color: color('text', 'inverse');
    height: calc(100vh - $h-header-d);
    max-height: 1080px;
    position: relative;

    @include mobile {
        &:not(&--small) {
            height: calc(100vh - $h-header-m);
        }
    }

    &--small {
        height: 17.5rem;
    }

    &:not(&--small) {
        min-height: 30rem;
    }

    &__background, &__overlay {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &__overlay {
        background: linear-gradient(0deg, rgba(2, 19, 38, 0.5), rgba(2, 19, 38, 0.5)), url(../img/dot-pattern.svg) repeat fixed;
    }

    &__content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5rem 1.5rem;
        position: relative;
        z-index: 1;
    }

    &__title {
        margin-top: auto;
        margin-bottom: auto;
        @include text-display-1;
    }

    &__description {
        @include text-small;
        max-width: 360px;
        opacity: .7;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2rem;
        background: url(../img/wave-edge.svg) no-repeat bottom center;
        background-size: 150%;
        // background-size: 100%;
        z-index: 1;
    }
}