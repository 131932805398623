*, *:after, *:before {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; }
a {text-decoration: none;color: inherit;}

html, body {
    background: color('background');
    font-family: 'Noto Sans', 'Noto Sans Georgian', Helvetica, sans-serif;
    color: color('text');
    font-size: 16px;
    line-height: 1.5rem;
    touch-action: pan-x pan-y;

	@media (min-width: 1921px) {
		font-size: 20px;
	}
}

html {
    scroll-behavior: smooth;
    scroll-padding: calc($h-header-d + 2rem) auto auto;

    @include mobile {
        scroll-padding: calc($h-header-m + 1.5rem) auto auto;
    }
}

body {
	height: 100%;
    overflow-x: hidden;
    cursor: default;
}

%outline {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: transparent;
}

%focus {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: color('border', 'focus');
    background: color('background', 'focus');
    animation: .5s ease-in 1 focus;

    @keyframes focus {
        0% {
            box-shadow: 0 0 0px rgba(color('accent'), .5);
        }
    
        100% {
            box-shadow: 0 0 48px rgba(color('accent'), 0);
        }
    }
}

*, button {
    @extend %outline;
}

:focus, .focus {
    @extend %focus;
}

::-moz-selection { 
    color: color('text', 'onBright');
    background: color('accent');
  }
  
::selection, mark {
    color: color('text', 'onBright');
    background: color('accent');
}

label {
	cursor: pointer;
}

a, button, [data-href], [data-modal-open] {
    cursor: pointer;
}

.page {
    min-height: calc(100vh - $h-footer-d);
    min-height: calc(100svh - $h-footer-d);
    padding-top: $h-header-d;

    @include mobile {
        min-height: calc(100vh - $h-footer-m);
        padding-top: $h-header-m;
    }

    &:not(:has(~ .footer)) {
        min-height: 100vh;
        min-height: 100svh;

        &.is-loading {
            height: 100vh;
            height: 100svh;
            overflow: hidden;
        }
    }

    &.is-loading {
        height: calc(100vh - $h-footer-d);
        height: calc(100svh - $h-footer-d);
        overflow: hidden;
    }

    &__title {
        @include text-display-2;
        margin-bottom: 2rem;

        @include mobile {
            margin-bottom: 1.5rem;
        }
    }

    &__subtitle {
        @include text-label;
        color: color('text', 'weak');
        margin-bottom: 1rem;
    }

    &__navlinks {
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;

        @include mobile {
            margin-top: -0.5rem;
            margin-bottom: 1rem;
        }

        .content--inner & {
            margin-top: -1rem;
            margin-bottom: .5rem;

            @include mobile {
                margin-top: 0rem;
                margin-bottom: 0.5rem;
            }
        }
    }

    &__subpages {
        margin-top: 3rem;

        @include mobile {
            margin-top: 1.5rem;
        }
    }
}

.content {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include mobile {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    &--inner {
        padding-top: 2.5rem;

        @include mobile {
            padding-top: 1rem;
        }
    }
}

.is-loading {
    position: relative;

    &::before, &::after {
        content: '';
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(color('background', 'base'), .3);
        backdrop-filter: blur(1px);
        z-index: 1;
    }

    &::after {
        position: absolute;
        z-index: 1;
        width: 2rem;
        height: 2rem;
        left: calc(50% - 1rem);
        top: calc(50% - 1rem);
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjOTFCNURCIiBkPSJNMTEgMXYyTDYgNSA1IDQgNCA1bDEgMS0yIDVIMXYyaDJsMiA1LTEgMSAxIDEgMS0xIDUgMnYyaDJ2LTJsNS0yIDEgMSAxLTEtMS0xIDItNWgydi0yaC0ybC0yLTUgMS0xLTEtMS0xIDEtNS0yVjFoLTJabTAgNHYzbC0xIDEtMi0zIDMtMVptMiAwIDMgMS0yIDMtMS0xVjVaTTYgOGwzIDItMSAxSDVsMS0zWm0xMiAwIDEgM2gtM2wtMS0xIDMtMlpNNSAxM2gzbDEgMS0zIDItMS0zWm0xMSAwaDNsLTEgMy0zLTIgMS0xWm0tNiAyIDEgMXYzbC0zLTEgMi0zWm00IDAgMiAzLTMgMXYtM2wxLTFaIi8+PC9zdmc+") no-repeat center / cover;
        animation: loader 1.5s ease-in-out infinite;
    }
}

.m {
    &y {
        &-05 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        &-10 {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &-15 {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
        &-20 {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        &-25 {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }
        &-30 {
            margin-top: 3.0rem;
            margin-bottom: 3.0rem;
        }
    }

    &t {
        &-05 {
            margin-top: 0.5rem;
        }
        &-10 {
            margin-top: 1rem;
        }
        &-15 {
            margin-top: 1.5rem;
        }
        &-20 {
            margin-top: 2rem;
        }
        &-25 {
            margin-top: 2.5rem;
        }
        &-30 {
            margin-top: 3.0rem;
        }
    }

    &b {
        &-05 {
            margin-bottom: 0.5rem;
        }
        &-10 {
            margin-bottom: 1rem;
        }
        &-15 {
            margin-bottom: 1.5rem;
        }
        &-20 {
            margin-bottom: 2rem;
        }
        &-25 {
            margin-bottom: 2.5rem;
        }
        &-30 {
            margin-bottom: 3.0rem;
        }
    }
}

.bg {
    &-secondary {
        background: color('background', 'strong');
    }
}

.sticky, [data-sticky] {
    @include sticky;
}

.a11y-skip {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0.5rem 1rem;
    background: color('accent');
    @include rounded;
    @include shadow-elevated;
    color: color('text', 'onBright');
    font-weight: 500;
    text-align: center;
    opacity: 0;
    z-index: -1;

    &:focus {
        opacity: 1;
        z-index: 100;
    }
}

.stack {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--h {
        @include breakpoints {
            flex-direction: row;
        }
    }

    &--xs {
        @include breakpoints {
            gap: 0.25rem;
        }
    }

    &--s {
        @include breakpoints {
            gap: 0.5rem;
        }
    }

    &--m {
        @include breakpoints {
            gap: 1.5rem;
        }
    }

    &--filled {
        @include breakpoints {
            & > * {
                flex: 1 0 auto;
            }
        }
    }

    &--distributed {
        @include breakpoints {
            justify-content: space-between;
        }
    }

    &--start {
        @include breakpoints {
            align-items: start;
        }
    }

    &--middle {
        @include breakpoints {
            align-items: center;
        }
    }

    &--scrollable {
        @include breakpoints {
            overflow-x: auto;
            @include simple-scrollbar;
        }
    }
}

.hide {
    @include breakpoints {
        display: none;
    }
}

.aspect-ratio {
    @include aspect-ratio;

    &--4-3 {
        @include aspect-ratio(4, 3);
    }

    &--3-4 {
        @include aspect-ratio(3, 4);
    }

    &--16-9 {
        @include aspect-ratio(16, 9);
    }
}

.align {
    &-start {
        align-self: start;
    }
}

.descriptor {
    font-size: 0.75rem;
    line-height: 1rem;
    color: color('text', 'weak');
    font-weight: 500;
}