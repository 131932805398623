.tabs {
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 0.5rem;

    &__item {
        padding: 0.5rem 1rem;
        @include rounded(2.5rem);
        background: color('background', 'strong');
        @include text-link-small;
        white-space: nowrap;

        &--active {
            background: color('primary');
            color: color('text', 'inverse');
        }

        &:not(&--active) {
            @include link-hover;
        }
    }
}