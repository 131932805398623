.steps {
    display: flex;
    align-items: center;
    overflow-x: auto;
    background: color('background', 'base');
    @include border;
    @include rounded;
    @include shadow-raised;

    &__item {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0.75rem 1rem;
        position: relative;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 600;

        &:not(:last-child)::after {
            content: '';
            display: block;
            width: 1rem;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 100%;
            background: url('../img/steps-border.svg') no-repeat left center;
        }

        &:not(&--current) {
            @include link-hover;
        }
        
        &--current {
            background: color('primary');
            color: color('text', 'inverse');

            &:not(:last-child)::after {
                background-image: url('../img/steps-border-active.svg');
            }
        }
    }
}