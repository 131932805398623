.icon {
    @include square(1.5rem);
    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    fill: color('icon');
    
    &--s {
        @include square(1rem);
    }

    &--expand {
        margin-left: 0.25rem;
        @include square(1rem);
    }

    &--illustrative {
        width: 2.5rem;
        height: 2.5rem;
        
        use {
            fill: color('icon', 'complementary');
        }
    }
}