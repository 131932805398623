.p404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &__image-wrap {
        width: 42rem;
        max-width: 100%;
    }

    &__image {
        position: relative;
        width: 100%;
        padding-bottom: 51%;
        background: url('../img/404/base.svg') no-repeat center / contain;
    }

    &__arrow {
        display: block;
        width: 3%;
        position: absolute;
        left: 48.6%;
        top: 31.1%;
        animation: compass-broken 2s ease-in-out infinite;
        pointer-events: none;
    }
    
    &__content {
        text-align: center;
        width: 34.5rem;
        max-width: 100%;
        margin-top: 2.5rem;
    }

    &__title {
        @include text-h1;
        margin-bottom: 0.5rem;
    }

    &__text {
        color: color('text', 'weak');

        a {
            @include text-link-inline;
        }
    }
}

@keyframes compass-broken {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(-90deg);
    }

    75% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}