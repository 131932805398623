.auth {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    background: url('../img/auth.webp') no-repeat center / cover;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: url(../img/wave-pattern.svg) repeat,
            linear-gradient(72.85deg, rgba(7, 65, 129, 0.5) 0%, rgba(0, 183, 203, 0.5) 100%),
            linear-gradient(180deg, rgba(0, 16, 34, 0) 0%, rgba(7, 65, 129, 0.5) 100%);
    }

    @include desktop {
        padding: 5vh 0;
    }

    @include mobile {
        align-items: flex-end;
    }

    &__wrap {
        width: 34.5rem;
        max-width: 100%;
        @include rounded;
        @include shadow-elevated;
        background: color('background', 'base');
        position: relative;
    }

    &__section {
        display: block;
        padding: 1.5rem 2rem;

        @include mobile {
            padding: 1rem;
        }

        & + & {
            border-top: $b-width solid color('border', 'base');
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__lang {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 .5rem;
        height: 2.5rem;
        flex-shrink: 0;
        position: relative;

        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        @include link-hover;

        .icon:first-child {
            margin-right: 0.5rem;
        }
    }

    &__title {
        @include text-h2;
        margin-bottom: 1rem;
    }

    &__alt {
        text-align: center;
        color: color('primary');
        @include text-link;
        @include link-hover;
    }
}