.fileinput {
    display: flex;
    align-items: center;
    height: 3.5rem;
    padding: 0.5rem;
    gap: 0.25rem;
    @include border;
    @include rounded;
    @include shadow-raised;

    &:not(&--loading):not(&--loaded) {
        color: color('text', 'weak');
    }
    
    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    &__wrap {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.5rem;
        gap: 0.5rem;
        overflow: hidden;
    }

    &__loader {
        @include square(1.5rem);

        .loader__icon {
            @include square(1.5rem);
        }
    }

    &--loaded &__icon {
        fill: color('positive');
    }

    &__caption {
        @include truncate-text;
    }

    &__button {
        flex-shrink: 0;
        padding: 0.5rem 0.75rem;
        color: color('primary');
        @include text-link-small;
        @include link-hover;

        .icon {
            @include square(1rem);
        }
    }
}