.mediacard {
    cursor: pointer;
    
    &__image {
        @include aspect-ratio(16, 9);
    }

    &__content {
        margin-top: 1rem;
    }

    &__title {
        @include text-card-title;
        @include transition;
    }

    &__date {
        @include text-small;
        color: color('text', 'weak');
        margin-top: 0.5rem;
    }

    &:hover &__title {
        color: color('accent');
    }
}