$grid-gap-h-m: 1rem;

$grid-breakpoints: (
	m: (size: 0, cols: 4, gap-h: $grid-gap-h-m, gap-v: 1rem),
	d: (size: $container-width + 1, cols: 12, gap-h: 1.5rem, gap-v: 2rem),
);

.container {
    width: 100%;
    max-width: calc($container-width + 2rem);
	padding-left: 1rem;
	padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;

	@media (min-width: 1921px) {
		max-width: calc(1600px + 2rem);
	}
}

.row {
	display: flex;
	flex-wrap: wrap;

	&--space-between {
		@include breakpoints {
			justify-content: space-between;
		}
	}

	&--center {
		@include breakpoints {
			justify-content: center;
		}
	}

	&--align-center {
		@include breakpoints {
			align-items: center;
		}
	}

	&--reverse {
		@include breakpoints {
			flex-direction: row-reverse;
		}
	}

	&--vertical {
		@include breakpoints {
			flex-direction: column;
		}
	}

	&--vertical-reverse {
		@include breakpoints {
			flex-direction: column-reverse;
		}
	}
}

.col {
	width: auto;
	flex-shrink: 0;

	&--fill {
		@include breakpoints {
			flex-grow: 1;
		}
	}
}

@each $name, $params in $grid-breakpoints {
	@media (min-width: map.get($params, size)) {
		.row {
			margin-left: calc(map.get($params, gap-h) / 2 * -1);
			margin-right: calc(map.get($params, gap-h) / 2 * -1);
			margin-top: calc(map.get($params, gap-v) * -1);
		}

		.col {
			padding-left: calc(map.get($params, gap-h) / 2);
			padding-right: calc(map.get($params, gap-h) / 2);
			margin-top: map.get($params, gap-v);
			max-width: 100%;		
		}
		
		@for $i from 1 through map.get($params, cols) {
			.col-#{$name}-#{$i} {
				width: calc((100 / map.get($params, cols) * $i * 1%));
			}
		}
	}
}