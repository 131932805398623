.modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;

    img {
        max-width: 100%;
    }

    &--shown {
        visibility: visible;
    }

    &__overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        @include square(100%);
        @include overlay;
        @include transition;
    }

    &__window {
        display: flex;
        flex-direction: column;
        width: 22.5rem;
        max-width: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
        background: color('background', 'base');
        position: relative;
        @include rounded;
        @include shadow-elevated;
        @include transition(.4s);

        &--width {
            &-6 { width: 34.5rem; }
            &-8 { width: 46.5rem; }
            &-10 { width: 58.5rem; }
            &-12 { width: 70.5rem; }
        }
    }

    &:not(&--shown) &__overlay {
        opacity: 0;
        visibility: hidden;
    }

    &:not(&--shown) &__window {
        opacity: 0;
        visibility: hidden;
        transform: translateY(2rem);
    }

    &__header {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 1rem 1.5rem;
        border-bottom: $b-width solid color('border', 'base');

        @include mobile {
            padding: 0.75rem 1rem;
        }
    }

    &__title {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include text-label;
    }

    &__close {
        flex-shrink: 0;
        cursor: pointer;
        @include square(1.5rem);

        .icon {
            @include transition;
        }

        &:hover .icon {
            fill: color('accent');
        }
    }

    &__content {
        max-height: 100%;
        overflow-y: auto;
        @include simple-scrollbar;
    }

    &__video {
        @include aspect-ratio(16, 9);
    }

    &__section {
        padding: 1rem 1.5rem;

        &--bordered {
            border-top: $b-width solid color('border', 'base');
        }

        @include mobile {
            padding: 0.75rem 1rem;
        }
    }

    &__footer {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: .75rem 1.5rem;
        border-top: $b-width solid color('border', 'base');

        & > * {
            flex: 1 0 0;
        }
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
    }

    &__column {
        flex: 1 0 50%;

        @include mobile {
            flex: 1 0 100%;
        }
    }

    &__docs {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 1rem;

        img {
            max-width: 15.25rem;
        }

        & > * {
            flex: 0 1 auto;
        }
    }
}