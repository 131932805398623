@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-regular.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-italic.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-500.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-600.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-700.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-800.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'),
         url('../fonts/noto-sans-v27-latin_cyrillic-ext_cyrillic-900.woff') format('woff'),
         local('Noto Sans');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 400;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-regular.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-regular.woff') format('woff'),
          local('Noto Sans Georgian');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 500;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-500.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-500.woff') format('woff'),
          local('Noto Sans Georgian');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 600;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-600.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-600.woff') format('woff'),
          local('Noto Sans Georgian');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 700;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-700.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-700.woff') format('woff'),
          local('Noto Sans Georgian');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 800;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-800.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-800.woff') format('woff'),
          local('Noto Sans Georgian');
}

@font-face {
     font-family: 'Noto Sans Georgian';
     font-style: normal;
     font-weight: 900;
     font-display: swap;
     src: url('../fonts/noto-sans-georgian-v36-georgian-900.woff2') format('woff2'),
          url('../fonts/noto-sans-georgian-v36-georgian-900.woff') format('woff'),
          local('Noto Sans Georgian');
}