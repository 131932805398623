.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
    background: color('background', 'strong');
    @include rounded;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;

    &--accent {
        background: color('accent');
        color: color('text', 'onBright');
    }
    &--primary {
        background: color('primary');
        color: color('text', 'inverse');
    }
    &--complementary {
        background: color('complementary');
        color: color('text', 'inverse');
    }
    &--success {
        background: color('positive');
        color: color('text', 'inverse');
    }
    &--warning {
        background: color('warning');
        color: color('text', 'inverse');
    }
    &--danger {
        background: color('negative');
        color: color('text', 'inverse');
    }
    &--extra-1 {
        background: color('extra', '1');
        color: color('text', 'onBright');
    }
    &--extra-2 {
        background: color('extra', '2');
        color: color('text', 'onBright');
    }
    &--extra-3 {
        background: color('extra', '3');
        color: color('text', 'onBright');
    }
    &--extra-4 {
        background: color('extra', '4');
        color: color('text', 'onBright');
    }
    &--extra-5 {
        background: color('extra', '5');
        color: color('text', 'onBright');
    }
    &--extra-6 {
        background: color('extra', '6');
        color: color('text', 'onBright');
    }
    &--extra-7 {
        background: color('extra', '7');
        color: color('text', 'onBright');
    }

    &--big {
        padding: 1rem 1.5rem;
        font-size: 1rem;
    }

    &--tiny {
        background: none;
        padding: 2px;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: color('icon');
        }
    }

    &--tiny &__icon {
        display: none;
    }
    &--tiny#{&}--accent {
        color: color('accent');
    }
    &--tiny#{&}--complementary {
        color: color('complementary');
    }
    &--tiny#{&}--primary {
        color: color('primary');
    }
    &--tiny#{&}--success {
        color: color('positive');
    }
    &--tiny#{&}--warning {
        color: color('warning');
    }
    &--tiny#{&}--danger {
        color: color('negative');
    }
    &--tiny#{&}--extra-1 {
        color: color('extra', '1');
    }
    &--tiny#{&}--extra-2 {
        color: color('extra', '2');
    }
    &--tiny#{&}--extra-3 {
        color: color('extra', '3');
    }
    &--tiny#{&}--extra-4 {
        color: color('extra', '4');
    }
    &--tiny#{&}--extra-5 {
        color: color('extra', '5');
    }
    &--tiny#{&}--extra-6 {
        color: color('extra', '6');
    }
    &--tiny#{&}--extra-7 {
        color: color('extra', '7');
    }

    &::before {
        background-color: currentColor;
    }

    .tag__icon {
        fill: currentColor;
    }
}