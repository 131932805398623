.table {
    width: 100%;
    text-align: left;

    tr {
        border-top: $b-width solid color('border', 'base');

        &.new {
            animation: tr-new 2s ease infinite;
            
            @keyframes tr-new {
                0% {
                    background: rgba(color('accent'), 0.2);
                }

                50% {
                    background: rgba(color('accent'), 0.1);
                }

                100% {
                    background: rgba(color('accent'), 0.2);
                }
            }
        }
    }

    td, th {
        padding: 0.75rem 0.5rem 1rem;
        vertical-align: top;
    }

    th {
        padding: 0.75rem 0.5rem;
    }

    th {
        background: color('background', 'strong');
        font-weight: 500;
    }

    &--s {
        td {
            padding: 0.5rem 0.5rem 0.75rem;
        }
    }

    &--xs {
        @include text-small;

        td {
            padding: 0.375rem 0.5rem 0.5rem;
        }
    }

    &__wrap {
        overflow-x: auto;
        @include simple-scrollbar;
    }

    &:not(&--instant) {
        @include border;
        @include rounded;

        td, th {
            &:first-child {
                padding-left: 1rem;
            }

            &:last-child {
                padding-right: 1rem;
            }
        }
    }

    &:not(&--maladaptive) {
        @include mobile {
            display: block;
            border: none;
    
            tbody, tr, td {
                display: block;
            }
    
            tr {
                @include border;
                @include rounded;
                @include shadow-raised;
            }
    
            tr + tr {
                margin-top: 0.75rem;
            }
    
            td {
                padding: 0.5rem 1rem;
    
                &:not(:first-child) {
                    border-top: $b-width solid color('border', 'base');
                }
    
                &::before {
                    content: attr(data-title);
                    display: block;
                    margin-bottom: 0.25rem;
                    @include text-small;
                    color: color('text', 'weak');
                }
            }
    
            thead {
                display: none;
            }
        }
    }
}

.cell {
    @include desktop {
        &--limited {
            &-3 { width: 3rem; }
            &-5 { width: 5rem; }
            &-10 { width: 10rem; }
            &-20 { width: 20rem; }
            &-30 { width: 30rem; }
        }

        &--main {
            width: 50%;
        }
    }
}