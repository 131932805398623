@mixin text-small {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

@mixin text-default {
    font-size: 1rem;
    line-height: 1.5rem;
}

@mixin text-link-small {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
}

@mixin text-link {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
}

@mixin text-link-inline {
    color: color('primary');
    @include link-hover;
    font-weight: 500;
    border-bottom: $b-width dotted currentColor;
}

@mixin text-label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

@mixin text-card-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
}

@mixin text-display-1 {
    font-size: 4.5rem;
    line-height: 5rem;
    text-transform: uppercase;
    font-weight: 900;

    @include mobile {
        font-size: 3rem;
        line-height: 4rem;
    }
}

@mixin text-display-2 {
    font-size: 3.5rem;
    line-height: 4.5rem;
    text-transform: uppercase;
    font-weight: 900;

    @include mobile {
        font-size: 2rem;
        line-height: 2.75rem;
    }
}

@mixin text-h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    font-weight: 800;

    @include mobile {
        font-size: 2rem;
        line-height: 2.75rem;
    }
}

@mixin text-h2 {
    font-size: 2rem;
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: 800;

    @include mobile {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}

@mixin text-h3 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    font-weight: 800;

    @include mobile {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}

@mixin heading-spacing {
    &:not(:first-child) {
        padding-top: 1rem;
    }
}

@mixin truncate-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin wrap-text {
    word-wrap: break-word;
    hyphens: auto;
}

@mixin ul {
    li {
        position: relative;
        padding-left: 1.5rem;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0.5rem;
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            @include rounded;
            background: color('primary');
        }
    }

    li + li {
        margin-top: 0.5rem;
    }
}